import { useContext, type ComponentProps, type FunctionComponent } from 'react';
import classNames from 'classnames';
import GlobalCart from '@fff-web/fff-cart';
import { ProspectNavContext } from '../../../../context/ProspectNavContext';
import { isVariation } from '../../../../helpers/functions/isVariation';
import { GROWTH_GLOBAL_NAV } from '../../../../utils/featureFlagVariants/growth-global-nav';
import {
  chosenRouteBaseOnVariation,
  createRoutes,
  createTrackingData,
  CTA_GET_STARTED,
  CTA_TRACK_LABEL,
  variationMapDesktop,
} from '../../../../utils/prospectGlobalNav';
import { ProspectNavItems } from '../ProspectNavItems/ProspectNavItems';
import * as styles from '../ProspectHeader/ProspectHeader.module.css';

const { VARIATION_A, VARIATION_B, VARIATION_C, VARIATION_E } = GROWTH_GLOBAL_NAV;

const ProspectNavVariants: FunctionComponent<{ isInView: boolean }> = ({ isInView }) => {
  const { env, mainProps, segmentTrackingData, isMobile, isTablet } =
    useContext(ProspectNavContext);
  // TODO: Correctly define this type
  const { handleCTA, labelCTA, showCart, variationKey, stickyCartPage, isLoginPage } =
    mainProps as never;
  const stickyCart = !isInView && stickyCartPage;
  const baseUrl = env.REACT_APP_BASE_URL;
  const loginUrl = env.REACT_APP_LOGIN_BASE_URL;
  const isVariationA = isVariation(variationKey, VARIATION_A);
  const isVariationB = isVariation(variationKey, VARIATION_B);
  const isVariationC = isVariation(variationKey, VARIATION_C);
  const isVariationE = isVariation(variationKey, VARIATION_E);
  const shouldShowCard = showCart && variationKey !== VARIATION_A;

  const routes = chosenRouteBaseOnVariation(
    createRoutes(baseUrl, loginUrl, isLoginPage),
    variationKey,
    variationMapDesktop,
  );

  const navigationClass = classNames(styles.navigation, {
    [styles.navigationVariationB]: isVariationB,
    [styles.navigationVariationC]: isVariationC || isVariationE,
  });

  const showJoinButton = isVariationA && !isMobile && !isTablet;

  const handleCTAOnClick = () => {
    if (handleCTA) {
      // @ts-expect-error TODO: we need to correctly define this type
      handleCTA();
    }

    createTrackingData({
      ctaName: CTA_TRACK_LABEL,
      segmentTrackingData,
    });
  };

  return (
    <>
      {!isMobile && !isTablet && (
        <nav data-testid="desktop-menu" aria-label="navigation menu" className={navigationClass}>
          <ul
            className={classNames(styles.listWrapper, {
              [styles.noLogin]: isLoginPage,
            })}
            role="menu"
          >
            <ProspectNavItems routes={routes} />
          </ul>
        </nav>
      )}
      {shouldShowCard && (
        <div className={classNames(styles.globalCart, { 'sticky-cart': stickyCart })}>
          <GlobalCart {...(mainProps as ComponentProps<typeof GlobalCart>)} env={env.name} />
        </div>
      )}
      {showJoinButton && (
        <button
          type="button"
          className={styles.joinBtn}
          onClick={handleCTAOnClick}
          data-testid="join-cta"
        >
          {labelCTA || CTA_GET_STARTED}
        </button>
      )}
    </>
  );
};

export default ProspectNavVariants;
