import { useEffect, useState } from 'react';
import { Compare, FFFStorage } from '@fff-web/fff-utilities';
import { getContent } from '../services';

const chatConfigurationContentKey = 'footer-chat-configuration';
const cacheDurationInMs = 30 * 60 * 1000; //15 minutes

const useChatConfiguration = () => {
  const [chatConfiguration, setChatConfiguration] = useState(
    FFFStorage.getFromStorage({
      key: chatConfigurationContentKey,
    }),
  );

  useEffect(() => {
    const chatConfigurationCache = FFFStorage.getFromStorage({ key: chatConfigurationContentKey });
    if (chatConfigurationCache) {
      if (!Compare.isEqualObject(chatConfiguration, chatConfigurationCache)) {
        setChatConfiguration(chatConfigurationCache);
      }
    } else {
      getContent('global-footer-chat').then((content) => {
        setChatConfiguration(content);
        FFFStorage.setToStorage({
          key: chatConfigurationContentKey,
          value: content,
          durationInMs: cacheDurationInMs,
        });
      });
    }
  }, [chatConfiguration]);

  return chatConfiguration;
};

export default useChatConfiguration;
