const COUPONS_KEY_PREFIX = 'FFF';
const COUPONS_KEY_NAME = 'discount_coupons';

const getCouponsKey = (version: string) => {
  return `${COUPONS_KEY_PREFIX}_${version.toLowerCase()}_${COUPONS_KEY_NAME}`;
};

const removeAllCoupons = (version: string) => {
  const COUPONS_KEY = getCouponsKey(version);
  localStorage.removeItem(COUPONS_KEY);
};

const getCoupons = (version: string) => {
  const COUPONS_KEY = getCouponsKey(version);
  const appliedCoupons = localStorage.getItem(COUPONS_KEY);
  return appliedCoupons ? JSON.parse(appliedCoupons) : [];
};

const storeCoupons = (version: string, appliedCoupons: Array<string>) => {
  const COUPONS_KEY = getCouponsKey(version);
  localStorage.setItem(COUPONS_KEY, JSON.stringify(appliedCoupons));
  return getCoupons(version);
};

const hasAppliedCoupon = (version: string) => {
  const appliedCoupons = getCoupons(version);
  return appliedCoupons.length > 0;
};

const removeCoupon = (version: string, couponToRemove: string) => {
  let appliedCoupons: string[] = getCoupons(version);
  appliedCoupons = appliedCoupons.filter((coupon) => coupon !== couponToRemove);
  if (appliedCoupons.length === 0) {
    removeAllCoupons(version);
  } else {
    storeCoupons(version, appliedCoupons);
  }
  return appliedCoupons;
};

const getPromoCodeErrorMessage = (errorType: string) => {
  return {
    COUPON_INVALID: 'The promo code entered is invalid.',
    COUPON_USER_MAX_REDEMPTIONS:
      'You’ve reached the maximum number of times you can use this promo code.',
    COUPON_MAX_REDEMPTIONS: 'This promo code has reached its maximum number of redemptions.',
    COUPON_ALREADY_REDEEMED: 'This promo code was already redeemed.',
    COUPON_ONLY_APPLIES_TO_SELECT_BOX_PLANS: 'This promo code only applies to select box plans.',
    COUPON_ONLY_APPLIES_TO_NON_BOX_PLAN_ITEMS: 'This promo code does not apply to box plans.',
    COUPON_DOES_NOT_APPLY_TO_ANNUAL_BOX_PLAN: 'This promo code does not apply to Annual box plans.',
    COUPON_DOES_NOT_APPLY_TO_SEASONAL_BOX_PLAN:
      'This promo code does not apply to Seasonal box plans.',
    COUPON_DOES_NOT_APPLY_TO_TRIAL_BOX_PLAN: 'This promo code does not apply to trial box plans.',
  }[errorType];
};

export {
  getCoupons,
  removeAllCoupons,
  storeCoupons,
  hasAppliedCoupon,
  removeCoupon,
  getPromoCodeErrorMessage,
};
