import { useEffect } from 'react';
import { initialize as initializeAnalytics } from '@fff-web/fff-analytics';
import { useUserContext } from '@fff-web/fff-utilities';
import { LegalFooter } from './components/LegalFooter';
import { LinkFooter } from './components/LinkFooter';
import { SocialMediaFooter } from './components/SocialMediaFooter';
import useBoxState from './hooks/useBoxState';
import useChatConfiguration from './hooks/useFooterChatConfiguration';
import useFooterContent from './hooks/useFooterContent';
import useUserWay from './hooks/useUserWay';
import { loadChat, removeAllWidgets } from './utils/loadChat';
import { loadPactSafe } from './utils/loadPactSafe';
import LogoSvg from './images/fff-logo.svg';
import './GlobalFooterDefault.scss';

interface GlobalFooterDefaultProps {
  minimalFooter?: boolean;
  hideChatWidget?: boolean;
  isUseAnalytic?: boolean;
  pathname?: string;
}

const GlobalFooterDefault = ({
  minimalFooter = false,
  hideChatWidget = false,
  isUseAnalytic = true,
  pathname,
}: GlobalFooterDefaultProps) => {
  const { footerContent: data } = useFooterContent();
  const {
    userInfo: { loggedIn, user },
  } = useUserContext();

  const chatConfig = useChatConfiguration();
  const { hideSwap } = useBoxState(loggedIn);
  useUserWay();

  useEffect(() => {
    if (isUseAnalytic) initializeAnalytics();
    loadPactSafe();
  }, [isUseAnalytic]);

  useEffect(() => {
    if (hideChatWidget) removeAllWidgets();
    else loadChat(chatConfig, user);
  }, [chatConfig, hideChatWidget, pathname, user]);

  if (!data) {
    return null;
  }

  return (
    <footer data-testid="global-footer" className={'fff-shared-footer-container'}>
      {!minimalFooter && (
        <div className={'fff-shared-footer-container-top'}>
          <div className="fff-logo-container">
            <img alt="logo" src={LogoSvg} className="fff-logo" />
          </div>
          <div className="fff-shared-footer-links-container">
            <LinkFooter
              categories={loggedIn ? data?.mainFooter?.loginState : data?.mainFooter?.logoutState}
              hideSwap={hideSwap}
            />
            <div className="fff-shared-footer-social-media-container">
              <SocialMediaFooter icons={data?.socialMedias.icons} />
            </div>
          </div>
        </div>
      )}
      <LegalFooter legals={data?.legal} isLoggedIn={loggedIn} />
    </footer>
  );
};

export { GlobalFooterDefault };
export type { GlobalFooterDefaultProps };
