import { useContext, useState } from 'react';
import classNames from 'classnames';
import { bool } from 'prop-types';
import { getParamFromUrl } from '@fff-web/fff-utilities';
import { ProspectNavContext } from '../../../../context/ProspectNavContext';
import { isVariation } from '../../../../helpers/functions/isVariation';
import useInView from '../../../../hooks/useInView';
import { GROWTH_GLOBAL_NAV } from '../../../../utils/featureFlagVariants/growth-global-nav';
import HamburgerMenuIcon from '../../../HamburgerMenuIcon';
import MobileMenu from '../MobileMenu/MobileMenu';
import ProspectBanner from '../ProspectBanner/ProspectBanner';
import ProspectNavVariants from '../ProspectNavVariants/ProspectNavVariants';
import fffLogo from '../../../../assets/fff-logo.svg';
import * as styles from './ProspectHeader.module.css';

const { VARIATION_A, VARIATION_D, VARIATION_B, VARIATION_C, VARIATION_E } = GROWTH_GLOBAL_NAV;

const ProspectHeader = ({ isLoginPage }) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const toggleMenu = () => setIsOpenMenu(!isOpenMenu);

  const {
    env,
    isMobile,
    isTablet,
    mainProps: { variationKey, showCart },
  } = useContext(ProspectNavContext);
  const { isInView, elementRef: headerRef } = useInView();
  const shouldShowCart = showCart && variationKey !== VARIATION_A;
  const isInvertedGTB = Boolean(getParamFromUrl('planselection'));

  const contentWrapper = classNames(styles.contentWrapper, {
    [styles.contentWrapperVariationB]:
      isVariation(variationKey, VARIATION_B) || isVariation(variationKey, VARIATION_C),
    [styles.contentWrapperVariationD]:
      isVariation(variationKey, VARIATION_D) || isVariation(variationKey, VARIATION_E),
    [styles.contentWrapperPosition]: !shouldShowCart,
  });
  const showProspectBanner = (isMobile || isTablet) && !isInvertedGTB && !isLoginPage;

  return (
    <>
      <header className={styles.header} ref={headerRef}>
        <div data-testid="content-wrapper" className={contentWrapper}>
          {(isMobile || isTablet) && <HamburgerMenuIcon isOpen={isOpenMenu} onClick={toggleMenu} />}
          <a className={styles.fffLogo} href={env.REACT_APP_BASE_URL}>
            <img src={fffLogo} alt="fabfitfun logo" />
          </a>
          <ProspectNavVariants isInView={isInView} />
        </div>
      </header>
      {(isMobile || isTablet) && (
        <MobileMenu data-testid="mobile-menu" variationKey={variationKey} isOpenMenu={isOpenMenu} />
      )}
      {showProspectBanner && (
        <ProspectBanner
          data-testid="prospect-banner"
          isMobile={isMobile}
          isTablet={isTablet}
          variationKey={variationKey}
        />
      )}
    </>
  );
};

ProspectHeader.propTypes = {
  isLoginPage: bool,
};

export default ProspectHeader;
