import { track } from '@fff-web/fff-analytics';
import { getHostName, getPactSafeVars } from '../utils/getEnv';
import isBrowser from '../utils/isBrowser';
import { FooterContentResponseData } from './types';

const LOCATION = 'footer';
const defaultHeaders = {
  'FFF-Auth': 'V1.1',
  'Content-Type': 'application/json; charset=utf-8',
};
const hostname = getHostName();

export async function getShippingAddress() {
  try {
    const response = await fetch(`https://user.${hostname}.com/users/shipping-address`, {
      credentials: 'include',
      headers: { ...defaultHeaders },
      body: JSON.stringify({
        activatedOn: true,
      }),
      mode: 'cors',
      method: 'GET',
    });
    return response;
  } catch (_) {
    return null;
  }
}

export async function getBoxState() {
  try {
    const response = await fetch(`https://box.${hostname}.com/boxes/self/box-state`, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const data = await response.json();
      return Promise.resolve(data?.boxState);
    }
    console.error('Could not get box-state', response);
    return Promise.reject(new Error());
  } catch (err) {
    console.error('Could not get box-state', err);
    return Promise.reject(new Error());
  }
}

export async function getFooterContent(): Promise<FooterContentResponseData> {
  return getContent('global-footer-v2');
}

export async function getContent(schemaName: string, contentId = 'default') {
  try {
    const response = await fetch(
      `https://content.${hostname}.com/contents/self/${schemaName}/${contentId}`,
      {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (response.ok) {
      const responseData = await response.json();
      return responseData.data;
    }

    return Promise.resolve();
  } catch (err) {
    console.error(`error to obtain schemaName ${schemaName} contentId ${contentId}`, err);
    return Promise.resolve();
  }
}

export const sendEmailToIterable = (email: string) => {
  track({
    trackName: 'GTB Email Capture',
    properties: {
      email,
      form_location: LOCATION,
    },
  });
  const pactSafeVars = getPactSafeVars();
  if (isBrowser()) {
    window.fffPactSafe.init(pactSafeVars.pactSafeSid);
    window.fffPactSafe.signContractsForGroup(email, pactSafeVars.pactSafeEmailOptInGroup);
  }
};

export async function getSubscriptionPlans() {
  try {
    const response = await fetch(
      `https://localization-api.${hostname}.com/api/1/subscription/plans`,
      {
        credentials: 'include',
        headers: { ...defaultHeaders },
        mode: 'cors',
        method: 'GET',
      },
    );
    if (response.ok) {
      return Promise.resolve(await response.json());
    }

    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
}
